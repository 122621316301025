
import { WeddingApi } from '@/api/WeddingApi';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

@Component({
  components: {
    vueDropzone: vue2Dropzone
  }
})
export default class AppMultiFileDropzone extends Vue {

  @Prop({ required: true, type: String })
  readonly type!: 'wlcm-home' | 'wedding';


  dropzoneOptions = {
    url: 'javascript:void(0)', // Prevent auto-upload
    thumbnailWidth: 150,
    createImageThumbnails: false,
    maxFilesize: 2, // MB
    addRemoveLinks: true,
    disablePreviews: true,
    acceptedFiles: 'image/*',
    autoProcessQueue: false, // Disable auto processing
    maxFiles: 10, // Allow up to 10 files
  }

  async handleFilesAdded(files: FileList) {
    const fileArray = Array.from(files);
    const formData = new FormData();
    fileArray.forEach(file => {
      formData.append('files', file);
    });

    try {
      if (this.type === 'wlcm-home') {
        const response = await WlcmHomeApi.uploadImages(this.$route.params.id, this.$route.params.campaignId, formData);
        this.fileUploaded(fileArray, response.data);
      } else if (this.type === 'wedding') {
        const response = await WeddingApi.uploadImages(this.$route.params.id, this.$route.params.eventId, formData);
        this.fileUploaded(fileArray, response.data);
      }
      
      // Remove uploaded files from the dropzone
      fileArray.forEach(file => {
        (this.$refs.myDropzone as any).removeFile(file);
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error (e.g., show error message to user)
    }
  }

  @Emit()
  fileUploaded(files: File[], response: any) {
    return { files, response };
  }
}
